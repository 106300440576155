export default {
    name: "signmeup",
    created () {
        window.emailSubmit = this.emailSubmit;
        $(window).resize(function () {
            setTimeout(function () {
                if ($(".sign-pic").length == 1 && !$(".navbar-toggler").is(":visible")) {
                    $(".sign-content").attr("style", "text-align: center");
                    // var ratio = 2.1810699588477367;
                    if ($(window).width() > 768) {
                        var imageheight = $(".sign-picbar").width() / 2.3610699588477367;
                        $(".sign-pic").attr("style", "height: " + imageheight + "px;");
                    } else {
                        // var imageheight = $(".sign-picbar").width() / 2.1810699588477367;
                        $(".sign-pic").attr("style", "margin-left: auto; margin-right: auto; width: 540px; height: 247px;");
                    }

                } else {

                    // var ratio = 2.1810699588477367;
                    if ($(window).width() > 768) {
                        var imageheight = $(".sign-picbar").width() / 1.1810699588477367;
                    } else {
                        var imageheight = $(".sign-picbar").width() / 2.1810699588477367;
                    }

                    if ($(".navbar-toggler").is(":visible"))
                        $(".sign-pic").attr("style", "height: " + imageheight + "px;");
                    else
                        $(".sign-pic").attr("style", "height: " + imageheight / 2 + "px;");
                }
            }, 10);
        })
    },
    mounted () {
        this.getFocusDate();
    },
    data () {
        return {
            lang: 1
        };
    },
    methods: {
        emailSubmit () {
            var email = $('#signEmail').val()
            if ($('#signCheck').is(':checked') == true && email !== '') {
                $.ajax({
                    type: "POST",
                    dataType: 'json',
                    url: this.$js.webservice + "SignUp",
                    data: { token: "webkey", mail: email, lang: this.lang },
                    success: function (response) {
                        //console.log(response);
                        if (response.replymsg == 'Invalid e-mail') {
                            alert("Invalid e-mail, please correct.");
                        } else {
                            alert("Registration success.");
                            $('#signEmail').val('');
                            $("#signCheck").prop("checked", false);
                        }
                    },
                    error: function (XMLHttpRequest) { }
                });
            } else {
                alert("Please confirm the correct information.");
            }
        },
        getFocusDate () {
            $.ajax({
                type: "post",
                url: this.$js.webservice + 'GetDeployedNewsletter',
                data: { token: 'webkey', limit: '2', lang: window.sessionStorage.getItem('lang') },
                dataType: "json",
                success: (data) => {
                    if (data.status === "SUCCESS") {
                        this.assignmentPictext(data.text)
                    }
                },
                error: function (error) {
                    console.log(error)
                }
            })
        },
        assignmentPictext (data) {
            var pictext = '';
            for (var i = 0, max = data.length; i < max; i++) {
                if (max == 0) {
                    $(".sign-content").hide();
                } else if (i < 2) {
                    pictext += '<div class="">'
                    // pictext += '<div class="sign-pictext">'
                    // // for (var n = 0, nMax = data[i].overview.split(/\n/g).length; n < nMax; n++) {
                    // //     pictext += '<div>' + stripHtml(data[i].overview.split(/\n/g)[n]) + '</div>'
                    // // }
                    // pictext += '</div>'
                    pictext += '<div class="sign-pic">'
                    pictext += '<img width="100%" height="100%" class="image-newsletterratio image-newsletterid' + data[i].newsletterid + '"></img>'
                    // pictext += '<div class="sign-pic-content">'
                    // pictext += '<span class="focus_information_content_text">' + data[i].title + '</span>'
                    // pictext += '</div>'
                    pictext += '</div>'
                    pictext += '<div class="sign-pictext cur-pointer">'
                    pictext += '<span><a href="../id/warrantinfocus?newsletterid=' + data[i].newsletterid + '"" class="color_0067B1">' + this.$t("m.Clickheretoviewthefullarticle") + '</a></span>'
                    pictext += '</div>'
                    pictext += '</div>'

                    $('.sign-picbar').append(pictext);

                    pictext = '';
                    this.getWifData(data[i].newsletterid);
                }
            }

            if ($(".sign-pic").length == 1 && !$(".navbar-toggler").is(":visible")) {
                $(".sign-content").attr("style", "text-align: center");
                if ($(window).width() > 768) {
                    var imageheight = $(".sign-picbar").width() / 2.3610699588477367;
                    $(".sign-pic").attr("style", "height: " + imageheight + "px;");
                } else {
                    // var imageheight = $(".sign-picbar").width() / 2.1810699588477367;
                    $(".sign-pic").attr("style", "margin-left: auto; margin-right: auto; width: 540px; height: 247px;");
                }

            } else {
                if ($(window).width() > 768) {
                    var imageheight = $(".sign-picbar").width() / 1.1810699588477367;
                } else {
                    var imageheight = $(".sign-picbar").width() / 2.1810699588477367;
                }
                if ($(".navbar-toggler").is(":visible"))
                    $(".sign-pic").attr("style", "height: " + imageheight + "px;");
                else
                    $(".sign-pic").attr("style", "height: " + imageheight / 2 + "px;");
            }
        },

        getWifData (id) {
            $.ajax({
                type: "post",
                url: this.$js.webservice + 'GetDeployedNewsletterActicle',
                data: { newsletterid: id, lang: window.sessionStorage.getItem('lang') },
                dataType: "json",
                success: function (data) {
                    if (data.status === "SUCCESS") {
                        // console.log(data);
                        $(".image-newsletterid" + id).attr('style', 'background-size: 100%; background-image: url(\'' + data.text[0].picturization + '\'); background-repeat: no-repeat;');
                    }
                },
                error: function (error) {
                    console.log(error)
                }
            })
        },

        selectLanguage (e) {
            this.lang = Number(e.target.value)
        },
        // 去除html
        stripHtml (html) {
            var tmp = document.createElement("DIV");
            tmp.innerHTML = html;
            return tmp.textContent || tmp.innerText || "";
        }
    }
}